<template></template>

<script>
import { tokenLogin } from '@/api/user'
export default {
  created() {
    let codeObj = this.getRequest()
    codeObj.state = decodeURIComponent(codeObj.state)
    tokenLogin({ code: codeObj.code }).then(res => {
      localStorage.setItem('token', res.data.token)
      this.$router.push(codeObj.state)
    })
  },
  methods: {
    getRequest() {
      let url = location.search; //获取url中"?"符后的字符串
      let theRequest = new Object();
      if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]);
        }
      }
      return theRequest;
    }
  }
}
</script>

<style>
</style>